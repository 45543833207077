export const getNewYorkMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-nyny-route.jpg'),
            mapLine: 'NewYorkPath',
            venueBg:  require('@/assets/maps/nyny.png'),
            venue: 'New York, New York',
            distance: '1.2',
            singleLineVenue: false,
            threeLineVenue: false
        }
    ]
}
